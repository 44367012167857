<script setup lang="ts">
import { ChevronRightIcon } from '@heroicons/vue/24/solid'
import type { HeroSplitWithImageStoryblok } from '~/types/storyblok'
import { blokLink } from '~/helpers/blokLink'
import StoryblokRichtext from '~/components/Global/StoryblokRichtext.vue'
import UiLinkButton from '~/components/Ui/UiLinkButton.vue'
import InfoBar from '~/components/Global/InfoBar.vue'

const props = defineProps<{
  blok: HeroSplitWithImageStoryblok
  printable?: boolean
}>()

const route = useRoute()

const heroVariants = {
  dark: 'bg-black text-white',
  light: 'bg-cream text-black',
  '': 'bg-cream text-black',
}

const heroHeight = {
  small: 'lg:min-h-[400px]',
  medium: 'lg:min-h-[600px]',
  large: 'lg:min-h-[800px]',
  '': 'lg:min-h-[400px]',
}

const imageHeight = {
  small: 'lg:max-h-[300px]',
  medium: 'lg:max-h-[500px]',
  large: 'lg:max-h-[700px]',
  '': 'lg:max-h-[300px]',
}

const buttonVariant = useStoryblokUi().buttonVariant(props.blok.button_variant)
const headingTag = useStoryblokUi().headingTag(props.blok.heading_tag)

const backgroundStyle = computed(() => {
  if (props.blok.background_image?.filename) {
    if (props.blok.overlay) {
      return {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)), url('${props.blok.background_image.filename}')`,
      }
    } else {
      return {
        backgroundImage: ` url('${props.blok.background_image.filename}')`,
      }
    }
  }
})

const roundedTop = computed<string>(() => {
  return route.name !== 'produkt-slug' ? 'sm:first:rounded-t-none' : ''
})
</script>

<template>
  <div
    v-editable="blok"
    :style="backgroundStyle"
    class="relative grid break-inside-avoid grid-cols-1 gap-y-10 bg-cover bg-center p-10 sm:rounded-lg lg:items-center lg:gap-x-8 lg:px-[130px]"
    :class="[
      heroVariants[props.blok.variant],
      heroHeight[props.blok.hero_height],
      roundedTop,
      props.blok.image_position_right ? 'lg:grid-cols-3' : 'lg:grid-cols-2',
    ]">
    <InfoBar
      v-if="blok.show_info_bar && blok.background_color"
      class="absolute left-1/2 top-0 order-first col-span-3 w-full -translate-x-1/2"
      :text-color="blok.text_color!"
      :background-color="blok.background_color"
      :info-text="blok.info_text!" />

    <div class="flex items-center" :class="{ 'lg:p-20': !blok.image_position_right }">
      <NuxtImg
        v-if="blok.image?.filename"
        :src="blok.image.filename"
        :alt="blok.image.alt"
        format="webp"
        provider="storyblok"
        sizes="300px md:500px lg:1200px"
        :class="imageHeight[props.blok.hero_height]"
        class="mx-auto rounded-md" />
    </div>
    <div
      class="my-10 xl:mr-20"
      :class="{ 'col-span-2 lg:order-first': blok.image_position_right, 'col-span-1': !blok.image_position_right }">
      <component :is="headingTag" class="mb-4 text-2xl font-semibold md:text-4xl">{{ blok.heading }}</component>
      <StoryblokRichtext v-if="blok.text" :text="blok.text" :dark="blok.variant === 'dark'" class="leading-relaxed" />
      <div v-if="!printable && blok.button_to && blok.button_to.cached_url">
        <UiLinkButton :to="blokLink(blok.button_to)" class="group mt-8 text-sm" :variant="buttonVariant">
          {{ blok.button_text ? blok.button_text : blok.button_to.cached_url }}
          <template #suffix>
            <ChevronRightIcon class="ml-4 w-4 transition-transform duration-300 ease-out group-hover:translate-x-1" />
          </template>
        </UiLinkButton>
      </div>
    </div>
  </div>
</template>
